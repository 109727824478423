import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_JUPITER_URL
})
axiosInstance.interceptors.request.use(
  function (config) {
    config.headers.ASKEY = process.env.VUE_APP_ATHEVA_ASKEY;
    config.headers.ASID = process.env.VUE_APP_ATHEVA_ASID;

    return config
  },
  function (error) {
    return Promise.reject(error);
  }
)
export const api = {
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body)
  },
  get(endpoint, body = null) {
    return axiosInstance.get(endpoint, body)
  },
}