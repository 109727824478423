import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/quem-somos',
    name: 'quem-somos',
    component: () => import('../views/QuemSomos.vue')
  },

  {
    path: '/distribuidores',
    name: 'distribuidores',
    component: () => import('../views/Distribuidores.vue')
  },
  {
    path: '/editoras',
    name: 'editoras',
    component: () => import('../views/Editoras.vue')
  }, {
    path: '/sistema-de-ensino',
    name: 'sistema',
    component: () => import('../views/Sistema.vue')
  },
  {
    path: '/prefeituras-e-governos',
    name: 'prefeitura',
    component: () => import('../views/Prefeitura.vue')
  },
  {
    path: '/empresas-e-promocoes',
    name: 'empresas',
    component: () => import('../views/Empresas.vue')
  },
  {
    path: '/coportativo-rh',
    name: 'RH',
    component: () => import('../views/RH.vue')
  },
  {
    path: '/cursos-e-ensino-tecnico',
    name: 'cursos',
    component: () => import('../views/Cursos.vue')
  },
  {
    path: '/universidades',
    name: 'universidades',
    component: () => import('../views/Universidades.vue')
  },
  {
    path: '/blog-post/:id',
    name: 'blog-post',
    component: () => import('../views/BlogPost.vue')
  },
  {
    path: '/blog',
    name: 'blog-posts',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/blog-page',
    name: 'blog-page',
    component: () => import('../views/BlogPage.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
})



export default router