<template>
  <footer id="/contato">
    <h1>Contato</h1>
    <p>Para conhecer mais sobre nossas soluções entre em contato conosco.</p>

    <div class="footer-flex container">
      <div class="infos">
        <div class="info-item">
          <a href="maito:comercial@atheva.com.br">comercial@atheva.com.br</a>
          <span>e-mail</span>
        </div>
        <div class="info-item">
          <a href="tel:1132570664">(11) 3257-0664</a>
          <span>Telefone</span>
          <span>Horário de atendimento de segunda a sexta das 9h às 18h</span>
        </div>
        <div class="info-item">
          <a href="https://goo.gl/maps/oGafjRmoXshCpP936" target="_blank"
            >Endereço</a
          >
          <span
            >Avenida Angélica, 2100 – Santa Cecília, São Paulo - SP,
            01228-903</span
          >
        </div>
      </div>

      <form class="formulario">
        <label for="nome">Nome</label>
        <input
          type="text"
          id="nome"
          placeholder="Digite o seu nome"
          required
          :disabled="loading"
          v-model="data.message.name"
        />
        <label for="email">E-mail</label>
        <input
          type="email"
          id="email"
          placeholder="Digite o seu e-mail"
          required
          :disabled="loading"
          v-model="data.message.email"
        />
        <label for="telefone">Telefone</label>
        <input
          type="tel"
          id="telefone"
          placeholder="Ex:(99) 9999-9999"
          required
          :disabled="loading"
          v-model="data.message.phone"
        />
        <label for="mensagem">Mensagem</label>
        <textarea
          name="mensagem"
          id="mensagem"
          placeholder="Digite sua mensagem aqui"
          required
          :disabled="loading"
          v-model="data.message.message"
        ></textarea>
        <button class="btn" @click.prevent="onSubmit">
          {{ loading ? "CARREGANDO..." : "ENVIAR" }}
        </button>
        <div class="wave">
          <img src="@/assets/icons/wave_form.svg" />
          <img src="@/assets/icons/wave_form.svg" />
          <img src="@/assets/icons/wave_form.svg" />
          <img src="@/assets/icons/wave_form.svg" />
        </div>
        <div class="politica">
          <a href="https://atheva-minerva.s3.sa-east-1.amazonaws.com/privacy_policy.pdf" target="_blank">Conheça nossa política de privacidade</a>
        </div>
      </form>
    </div>

    <div class="bg-footer">
      <div class="wave-top"></div>
      <div class="bg-pattern">
        <div class="informacoes-footer">
          <div class="footer-container container" data-aos="fade-down">
            <!-- <img src="@/assets/logo-branca.svg" alt="Atheva" /> -->
            <img src="@/assets/logos/atheva-logo.svg" alt="Atheva" />
            <div class="item-footer">
              <span>Institucional</span>
              <router-link to="/quem-somos">Quem somos</router-link>
              <a href="/#onde-atuamos" @click.prevent="toHash('#onde-atuamos')"
                >Onde atuamos</a
              >
              <a href="/#nosso-AVA" @click.prevent="toHash('#nosso-AVA')"
                >Nosso AVA</a
              >
              <a
                href="/#quem-nos-conhece"
                @click.prevent="toHash('#quem-nos-conhece')"
                >Quem nos conhece</a
              >
              <a href="/#blog" @click.prevent="toHash('#blog')">Blog</a>
            </div>
            <div class="item-footer">
              <span>Suporte</span>
              <a href="#/contato">Contato</a>
            </div>
            <div class="item-footer">
              <span>Atheva Tecnologia em Educação</span>
              <span>Endereço</span>
              <a href="https://goo.gl/maps/oGafjRmoXshCpP936" target="_blank"
                >Avenida Angélica, 2100 – Santa Cecília <br />São Paulo/SP<br />CEP:
                01228-903</a
              >
            </div>
            <div class="item-footer">
              <span>Siga a Atheva nas redes sociais</span>
              <div class="item-redes">
                <a
                  href="https://www.linkedin.com/company/atheva-tecnologia-em-educa-o/?trk=similar-pages&originalSubdomain=br"
                  target="_blank"
                  class="rede-social"
                >
                  <img
                    src="@/assets/icons/linkedin.svg"
                    alt="Linkedin Atheva"
                  />
                </a>
                <a
                  href="https://www.instagram.com/athevaoficial/"
                  class="rede-social"
                  target="_blank"
                >
                  <img
                    src="@/assets/icons/instagram.svg"
                    alt="instagram Atheva"
                  />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=5511948693415"
                  target="_blank"
                  class="rede-social"
                >
                  <img src="@/assets/icons/whatsapp.svg" alt="whatsapp Atheva"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UCsheyK41nXZsbTUEVRUdeWQ"
                  target="_blank"
                  class="rede-social"
                >
                  <img src="@/assets/icons/youtube.svg" alt="youtube Atheva" />
                </a>
              </div>
              <a
                href="https://atheva-minerva.s3.sa-east-1.amazonaws.com/privacy_policy.pdf"
                target="_blank"
                download
                class="politica-privacidade"
              >
                Política de Privacidade
              </a>
            </div>
          </div>
          <p class="direitos-reservados">
            Todos os direitos reservados. © Atheva 2011 - {{ ano_atual }}.
          </p>
        </div>
      </div>
    </div>

    <Notificacao :modal="modal" />
  </footer>
</template>

<script>
import { api } from "@/services.js";
import Notificacao from "@/components/Notificacao";

export default {
  name: "TheFooter",
  data() {
    return {
      loading: false,
      modal: {
        show: false,
        type: "",
        title: "",
        mensagem: "",
      },
      data: {
        platform: "atheva_web",
        message: {
          name: "",
          email: "",
          phone: "",
          message: "",
        },
      },
    };
  },
  components: {
    Notificacao,
  },
  computed: {
    ano_atual() {
      return new Date().getFullYear();
    },
  },
  methods: {
    toHash(hash) {
      this.$router.push(`/${hash}`);
      setTimeout(() => {
        const h = document.querySelector(hash);
        if (h) {
          window.scrollTo({
            top: h.offsetTop,
            behavior: "smooth",
          });
        }
      }, 500);
    },
    onSubmit() {
      if (
        this.data.message.name &&
        this.data.message.email &&
        this.data.message.phone &&
        this.data.message.message
      ) {
        this.loading = true;
        api
          .post("/contact", this.data)
          .then((response) => {
            if (response.status == 201) {
              this.modal = {
                type: "success",
                title: "Dados enviados com sucesso!",
                mensagem: "Seus dados foram enviados com sucesso.",
                show: true,
              };
              this.data = {
                platform: "atheva_web",
                message: {
                  name: "",
                  email: "",
                  phone: "",
                  message: "",
                },
              };
            } else {
              this.modal = {
                type: "error",
                title: "Um erro ocorreu!",
                mensagem:
                  "Verifique os dados e tente novamente ou envie para 'comercial@atheva.com.br'",
                show: true,
              };
            }
          })
          .catch((err) => {
            console.log(err);
            this.modal = {
              type: "error",
              title: "Um erro ocorreu!",
              mensagem:
                "Verifique os dados e tente novamente ou envie para 'comercial@atheva.com.br'",
              show: true,
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        alert("Preencha os campos necessários");
        document.querySelector("#nome").focus();
      }
    },
  },
};
</script>

<style scoped>
footer {
  margin-top: 120px;
  position: relative;
}
h1 {
  color: #000000;
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 16px;
  text-align: center;
}
p {
  font-size: 18px;
  color: #747474;
  text-align: center;
}

@media screen and (max-width: 768px) {
  footer {
    margin-top: 60px;
    overflow: hidden;
  }
  h1 {
    font-size: 18px;
  }
  p {
    font-size: 15px;
  }
}
.footer-flex {
  margin-top: 48px;
  display: flex;
  gap: 80px;
  align-items: center;
  justify-content: space-between;
}

.info-item {
  display: block;
}
.info-item a {
  font-size: 28px;
  font-weight: 800;
  color: #000000;
  transition: 0.3s;
}
.info-item a:hover {
  color: #ffa510;
}
.info-item span {
  display: block;
  color: #393939;
  font-size: 18px;
  margin-top: 8px;
}
.info-item + .info-item {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .footer-flex {
    margin-top: 32px;
    display: block;
  }
  .info-item a {
    font-size: 20px;
  }
  .info-item span {
    font-size: 15px;
  }
  .info-item + .info-item {
    margin-top: 30px;
  }
}
/* formulario */
.formulario {
  padding: 40px 32px;
  /* width: 600px; */
  flex: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 16px #0000001a;
  border-radius: 8px;
  position: relative;
}
button {
  width: 220px;
  margin-left: auto;
}
textarea {
  height: 134px;
  resize: none;
}
@media screen and (max-width: 768px) {
  .formulario {
    margin-top: 32px;
  }
}
.politica {
  text-align: center;
  text-decoration: underline;
  margin-top: 1rem;
  color: #ccccb4;
}

.politica a{
  color: #ccccb4;
}

.wave {
  position: absolute;
  left: -90px;
  bottom: 20px;
}
.wave img {
  margin-bottom: 10px;
}
.bg-footer {
  position: relative;
}
.wave-top {
  width: 100%;
  height: 156px;
  background: url("../assets/icons/wave-footer.svg") no-repeat;
  background-size: 130%;
  background-position: 5% 0;
}
.bg-pattern {
  background: url("../assets/img/pattern-footer.png") #232f3e;
}
.informacoes-footer .pattern {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 768px) {
  .wave-top {
    margin-top: 100px;
    background-size: auto;
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 140px 0;
  gap: 60px;
}
.footer-container .item-footer span {
  display: block;
  color: #fff;
  font-weight: 800;
  margin-bottom: 8px;
  font-size: 14px;
}
.footer-container .item-footer a {
  display: block;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
}
.footer-container .item-footer a + a {
  margin-top: 10px;
}
.item-redes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.rede-social {
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  /* background: #fff; */
  border-radius: 50%;
  color: #000 !important;
  font-weight: 800 !important;
  margin: 0 !important;
}
.politica-privacidade {
  margin-top: 16px;
}
@media screen and (max-width: 768px) {
  .footer-container {
    padding: 32px;
    gap: 32px;
    flex-direction: column;
  }
}

.direitos-reservados {
  background: #37475a;
  padding: 20px;
  width: 100%;
  font-size: 14px;
  color: #fff;
}
a.rede-social.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>