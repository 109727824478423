<template>
  <header :class="{ menuMobile: menuActive }">
    <nav class="container desktop" data-aos="fade-down">
      <div class="itens-link">
        <router-link to="/" class="logo">
          <img src="@/assets/logos/atheva-logo-header.svg" alt="Atheva" />
        </router-link>

        <router-link to="/quem-somos"> Quem somos </router-link>
        <a href="/#onde-atuamos" @click.prevent="toHash('#onde-atuamos')"
          >Onde atuamos</a
        >
        <a href="#nosso-AVA" @click.prevent="toHash('#nosso-AVA')">
          Nosso AVA</a
        >
        <a
          href="#quem-nos-conhece"
          @click.prevent="toHash('#quem-nos-conhece')"
        >
          Quem nos conhece
        </a>
        <router-link to="/blog-page"> Blog </router-link>
        <a href="#/contato"> Contato </a>
      </div>
      <a href="tel:1132570664" class="telefone">
        <img src="@/assets/icons/phone.svg" />
        (11) 3257-0664
      </a>
    </nav>

    <nav class="container mobile" data-aos="fade-down">
      <div class="itens-link">
        <router-link to="/" class="logo">
          <img src="@/assets/logos/atheva-logo-header.svg" alt="Atheva" />
        </router-link>

        <img
          src="@/assets/icons/menu.svg"
          alt="Menu"
          class="icon-menu"
          v-show="!menuActive"
          @click="menuActive = true"
        />
        <img
          src="@/assets/icons/close-menu.svg"
          alt="Fechar menu"
          class="icon-menu"
          v-show="menuActive"
          @click="menuActive = false"
        />
      </div>

      <div class="itens-mobile" data-anima="top" v-show="menuActive">
        <router-link to="/"> Home </router-link>
        <router-link to="/quem-somos"> Quem somos </router-link>
        <a href="/#onde-atuamos" @click.prevent="toHash('#onde-atuamos')"
          >Onde atuamos</a
        >
        <a href="#nosso-AVA" @click.prevent="toHash('#nosso-AVA')">
          Nosso AVA</a
        >
        <a
          href="#quem-nos-conhece"
          @click.prevent="toHash('#quem-nos-conhece')"
        >
          Quem nos conhece
        </a>
        <a href="#blog" @click.prevent="toHash('#blog')"> Blog </a>
        <a href="#/contato"> Contato </a>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      menuActive: false,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
    },
  },
  methods: {
    toHash(hash) {
      this.$router.push(`/${hash}`);
      setTimeout(() => {
        const h = document.querySelector(hash);
        if (h) {
          window.scrollTo({
            top: h.offsetTop,
            behavior: "smooth",
          });
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.mobile {
  display: none;
}
.icon-menu {
  cursor: pointer;
  padding: 10px;
}

.logo {
  margin-right: 50px;
}
.logo img {
  max-width: 100px;
}
.itens-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}
.itens-link a {
  color: #747474;
  font-size: 15px;
  transition: 0.3s;
  padding: 5px;
}
.itens-link a.router-link-exact-active,
.itens-link a:hover {
  color: #000;
}
.itens-link a.router-link-exact-active::after,
.itens-link a:hover::after {
  content: "";
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background: #f9b010;
  display: block;
  margin-top: 5px;
  position: absolute;
}
.itens-link a.logo.router-link-exact-active::after,
.itens-link a.logo:hover::after {
  display: none;
}
.telefone {
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 800;
  color: #000000;
}
/* responsivo */
@media screen and (max-width: 768px) {
  .menuMobile {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    background-color: #fff;
  }

  .desktop {
    display: none;
  }
  .mobile {
    padding: 16px;
    display: block;
  }
}

.itens-mobile {
  margin: 24px 0;
}
.itens-mobile a {
  display: block;
  padding: 20px 0;
  transition: 0.3s;
  font-size: 18px;
}
.itens-mobile a:hover {
  font-weight: 600;
}
.itens-mobile a + a {
  border-top: 1px solid #00000010;
}
.telefone:hover {
  color: #f9b010;
}
</style>