<template>
  <div id="app">
    <TheHeader />
    <main id="main">
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
    <TheFooter />

    <div class="whats-fixed" @click="openWhats"></div>
  </div>
</template>


<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  components: {
    TheHeader,
    TheFooter,
  },
  methods: {
    openWhats() {
      open("https://api.whatsapp.com/send?phone=5511948693415");
    },
  },
  created() {
    AOS.init();
  },
};
</script>

<style>
@import "./assets/css/main.css";
.whats-fixed {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: url("./assets/icons/whatsapp-fixed.svg") no-repeat center center
    #3fb43f;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.3s;
}
.whats-fixed:hover {
  transform: scale(1.05);
}
</style>
