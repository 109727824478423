<template>
  <section class="modal-container" v-if="ativo">
    <div class="modal" :class="modal.type">
      <div class="header-modal">
        <div class="check">
          <img
            src="@/assets/icons/CheckSucesso.svg"
            v-if="modal.type == 'success'"
          />
          <h3>{{ modal.title }}</h3>
        </div>
        <button class="fechar" @click="closeModal"></button>
      </div>
      <p>{{ modal.mensagem }}</p>

      <div class="btn-voltar">
        <button @click="closeModal">VOLTAR</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Notificacao",
  data() {
    return {
      ativo: this.modal.show,
    };
  },
  props: {
    modal: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    modal(value) {
      this.ativo = value.show;
    },
  },
  methods: {
    closeModal() {
      this.ativo = false;
    },
  },
};
</script>

<style scoped>
.modal-container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal {
  margin-top: -120px;
  background: white;
  padding: 32px;
  position: relative;
  width: 570px;
  height: 295px;
  border-radius: 4px;
  box-shadow: 0px 19px 38px #00000042;
  animation: modal 0.3s forwards;
}
@media screen and (max-width: 768px) {
  .modal {
    width: 90%;
  }
}

.modal.success {
  border-top: 5px solid #269800;
}
.modal.error {
  border-top: 5px solid #ffa510;
}
@keyframes modal {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.header-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #c2c1c1;
  margin-bottom: 20px;
}
.header-modal h3 {
  color: #424242;
  font-size: 18px;
  font-weight: 600;
}

.check {
  display: flex;
  align-items: center;
  gap: 16px;
}
.fechar {
  width: 18px;
  height: 18px;
  border: none;
  padding: 10px;
  background: transparent url("../assets/icons/fechar.svg") no-repeat center
    center;
  cursor: pointer;
  transition: 0.3s;
}
.fechar:hover {
  transform: scale(1.1);
}
p {
  color: #424242;
  font-size: 14px;
  line-height: 1.5;
}

.btn-voltar {
  margin: 40px 0;
  display: flex;
  justify-content: flex-end;
}
.btn-voltar button {
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  padding: 0 40px;
  height: 45px;
  color: #9e9e9e;
  font-weight: 600;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 768px) {
  .btn-voltar button {
    font-size: 13px;
    padding: 0 20px;
  }
}
.btn-voltar button:hover {
  background: #424242;
  color: #fff;
  border-color: transparent;
}
</style>